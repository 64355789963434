
import Vue from "vue";
import { capitalizeFirstLetters } from "@/helpers/generalHelpers";
import {
  agencyMapState,
  agencyMapMutations,
  agencyMapGetters,
  agencyMapActions
} from "@/store/modules/agency";
import { SET_EDIT_FIELD } from "@/store/mutation-types";
import { authMapGetters } from "@/store/modules/auth";
import { companiesMapGetters } from "@/store/modules/companies";
import { userMapGetters } from "@/store/modules/user";
import { FormBlock } from "@/components/FormBuilder/types";
import { agencyForm } from "@/forms/admin/agency";
import { omit, get } from "lodash";
import { convertValuesToLowerCase, objectDifference } from "@/helpers";
import { agencyStatus } from "@/helpers/selectOptions";
import { sameAsPhysicalAddressChangedHandler } from "@/helpers/isSameAsPhysicalAddress";
import { IAgency } from "@/store/modules/agency/types";
export interface IAgencyData {
  errorMessage: string;
  successMessage: string;
  companyIds: any[];
  hasValidCredentials: boolean;
  statusOptions: any[];
  agencyNOtFound: boolean;
  validationData: any;
  bulkAction: { disabled: boolean; title: string }[];
  emitOnAction: boolean;
  hideUserListView: boolean;
  topButtonForUserList: string[];
  initialized: boolean;
  tableActionUsers: string[];
  tableActionAgencies: string[];
  currentTableShowing: string;
  changingTables: boolean;
  nextRoute: Function;
  isAdmin: true;
  isLoading: boolean;
  defaultPhoneValue: any;
  agency: Partial<IAgency>;
  hasUpdatedAgencyData: boolean;
  hasAcknowledgedEzlynx: boolean;
  warningString: string;
}
export default Vue.extend({
  props: {
    agencyIdProp: {
      type: String,
      required: false
    }
  },
  data(): Partial<IAgencyData> {
    return {
      errorMessage: "",
      successMessage: "",
      companyIds: [],
      hasValidCredentials: false,
      statusOptions: agencyStatus,
      agencyNOtFound: false,
      validationData: {
        formIsValid: false
      },
      bulkAction: [
        {
          title: "Add Users To Agency",
          disabled: false
        }
      ],
      tableActionUsers: ["Show Sub Agency List"],
      tableActionAgencies: ["Show Agency Members List"],
      emitOnAction: true,
      hideUserListView: false,
      topButtonForUserList: ["Add New User To Agency"],
      initialized: false,
      currentTableShowing: "",
      changingTables: false,
      nextRoute: () => {},
      agency: {},
      hasUpdatedAgencyData: false,
      hasAcknowledgedEzlynx: false,
      warningString: ""
    };
  },
  mounted() {
    const { role = "" } = this.$getCurrentUser;
    if (["agencyAdmin", "subAdmin"].includes(role)) {
      this.currentTableShowing = "agency";
    } else {
      this.currentTableShowing = "users";
    }
    if (this.agencyId) {
      this.getAgency(this.agencyId)
        .then((data: IAgency) => {
          this.agency = data;
        })
        .catch((error: any) => {
          this.errorMessage = error.message;
          this.$bugSnagClient.notify(error);
        });
    }
  },
  methods: {
    ...agencyMapMutations({ editField: SET_EDIT_FIELD }),
    ...agencyMapActions(["getAgency"]),
    formFieldBlurHandler({ key, value }: { key: any; value: any }): void {
      if (key === "agencyName") {
        const capitalizedValue = capitalizeFirstLetters(value);
        this.editField({ key, value: capitalizedValue });
      }
      if (key === "agencyCode") {
        this.editField({
          key,
          value: value.toUpperCase()
        });
        if (
          value &&
          value.length >= 3 &&
          !value.toUpperCase().startsWith("AGA-") &&
          !value.toUpperCase().startsWith("CLL-")
        ) {
          this.$modal.show("selectPrefixModal");
        }
      }
    },
    formFieldChangedHandler({ key, value }: { key: any; value: any }): void {
      const addressKeys = ["mailingAddress", "physicalAddress"];
      if (addressKeys.includes(key)) {
        const { fieldKey, value: fieldValue } = value;
        if (fieldKey.includes("isSameAsPhysicalAddress")) {
          sameAsPhysicalAddressChangedHandler(
            key,
            fieldValue,
            this.editing?.physicalAddress || {},
            this.editField
          );
        }
        this.editField({
          key: `${key}.${fieldKey}` as any,
          value: fieldValue
        });
        if (
          key === "physicalAddress" &&
          this.editing &&
          this.editing.mailingAddress.isSameAsPhysicalAddress
        ) {
          this.editField({
            key: `mailingAddress.${fieldKey}` as any,
            value: fieldValue
          });
        }
      } else if (
        key === "allowEzlynxRating" &&
        value &&
        !this.hasAcknowledgedEzlynx
      ) {
        this.warningString = `By accepting this option, you acknowledge that ${
          this.editing?.agencyCode ? this.editing.agencyCode : "agency"
        }'s' data on this platform may be shared with Ezlynx.`;
        this.$modal.show("acknowledgeDataSharing");
      } else {
        this.hasAcknowledgedEzlynx = false;
        this.editField({ key, value });
      }
    },
    prefixSelected(value: string): void {
      if (value && value !== "NONE") {
        this.editField({
          key: "agencyCode",
          value: `${value}-${this.editing.agencyCode.toUpperCase()}`
        });
      }
      this.$modal.hide("selectPrefixModal");
    },
    acceptEzlynxAcknowledgement(): void {
      this.$modal.hide("acknowledgeDataSharing");
      this.hasAcknowledgedEzlynx = true;
      this.editField({
        key: "allowEzlynxRating",
        value: true
      });
    },
    declineEzlynxAcknowledgement(): void {
      this.$modal.hide("acknowledgeDataSharing");
      this.hasAcknowledgedEzlynx = false;
      this.editField({
        key: "allowEzlynxRating",
        value: false
      });
    }
  },
  computed: {
    ...agencyMapGetters(["getEditingAgency", "getAgencyById"]),
    ...authMapGetters(["getCurrentUser"]),
    ...agencyMapState(["editing", "makingApiRequest"]),
    ...companiesMapGetters(["companies"]),
    ...userMapGetters(["getUnderWritersFromStore"]),
    editingFormatted(): any {
      return { ...this.editing, agencyCompanies: this.agencyCompanies };
    },
    companyOptions(): { label: string; value: string }[] {
      const initData = this.$store.state.auth["initData"];
      const { companies = [] } = initData;
      return companies
        .filter((company: any) => company.companyName && company._id)
        .map((company: any) => ({
          label: `${
            company.companyNumber == 99 ? 20 : company.companyNumber
          } - ${company.companyName}`,
          value: company._id,
          companyName: company.companyName
        }))
        .sort(function(
          str1: { companyName: string },
          str2: { companyName: string }
        ) {
          return str1.companyName.localeCompare(str2.companyName);
        });
    },
    updatedFields(): any {
      const edited = this.getEditingAgency;
      const original = this.agency;
      const allowEmptyFields = [
        "mailingAddress.streetType",
        "physicalAddress.streetType"
      ];
      return edited && original
        ? omit(
            objectDifference(edited, original, allowEmptyFields),
            "underWriterData"
          )
        : {};
    },
    convertedUpdatedFields(): boolean {
      let editingEgency = this.getEditingAgency;
      let agency = this.agency;

      if (!this.isAdmin) {
        editingEgency = omit(editingEgency, ["allowInsurorsSeacoast"]);
        agency = omit(agency, ["allowInsurorsSeacoast"]);
      }
      const convertedEditedAgency = convertValuesToLowerCase(
        editingEgency as IAgency
      );
      const convertedOriginalAgency = convertValuesToLowerCase(
        agency as IAgency
      );
      const toIgnore = [
        "subAgencyOf",
        "physicalAddress.unitNumber",
        "mailingAddress.unitNumber",
        "mailingAddress.streetType",
        "physicalAddress.streetType",
        "mailingAddress.streetDirection",
        "physicalAddress.streetDirection"
      ];
      const convertedDifference = objectDifference(
        convertedEditedAgency,
        convertedOriginalAgency,
        toIgnore
      );
      return !!Object.values(convertedDifference).length;
    },
    disablePrimaryToolbar(): boolean {
      return !this.convertedUpdatedFields || !this.formIsValid;
    },
    agencyId(): any {
      return this.$route.params.agencyId || this.agencyIdProp;
    },
    agencyCompanies(): any {
      const companies: any = get(this, "getEditingAgency.companies", []) || [];
      return companies.map((comp: any) => comp.companyName).join(", ");
    },
    agencyFields(): FormBlock[] {
      const { agencyAdminData = null, subAdminData = null } = this.editing;
      const hasAgencyAdminInfo =
        Boolean(agencyAdminData) || Boolean(subAdminData);
      return agencyForm(this.isAdmin, hasAgencyAdminInfo);
    },
    formIsValid(): boolean {
      return Boolean(
        this.validationData.formIsValid &&
          this.editing.companyIds.length &&
          this.editing.status
      );
    },
    isAdmin(): boolean {
      return this.getCurrentUser?.role === "admin";
    },
    isOnboarding(): boolean {
      return (
        this.getCurrentUser?.status === "pendingTutorial" ||
        this.getCurrentUser?.status === "completedTutorial"
      );
    },
    queryOverride(): any {
      return {
        "data.agencyId": this.editing?._id,
        activityType: "AgencyActivity"
      };
    }
  }
});
